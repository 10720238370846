import { BaseService } from "./base.service";
import { User } from "../queries/models/user.dto";
import { API_ENDPOINTS } from "../utils/endpoints";
import { AddEditUser } from "../queries/models/users/add-edit-user.dto";
import { Role } from "../queries/models/users/role.dto";

class UsersService extends BaseService {
  async getAll(): Promise<User[]> {
    const url = `${this.endpointPath}`;
    return this.http.get<User[]>(url).then((res) => res.data);
  }

  async create(user: AddEditUser): Promise<User> {
    const url = `${this.endpointPath}`;
    return this.http.post<User>(url, user).then((res) => res.data);
  }

  async update(user: AddEditUser): Promise<User> {
    const url = `${this.endpointPath}/${user.id}`;
    return this.http.put<User>(url, user).then((res) => res.data);
  }

  async delete(id: number): Promise<void> {
    const url = `${this.endpointPath}/${id}`;
    return this.http.delete(url);
  }

  async getRoles(): Promise<Role[]> {
    const url = `${this.endpointPath}/roles`;
    return this.http.get<Role[]>(url).then((res) => res.data);
  }
}

export const usersService = new UsersService(API_ENDPOINTS.USERS);
