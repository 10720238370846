import { useMemo } from "react";

import { useAllDataSourcesQuery } from "../queries/data-sources.query";
import { MultiSelect } from "primereact/multiselect";
import { Dropdown } from "primereact/dropdown";
import { DataSource } from "../queries/models/data-source.dto";
import { Button } from "primereact/button";

interface DataSourcesSelectorProps {
  dataSources: DataSource[];
  setDataSources: (dataSources: DataSource[]) => void;
  single?: boolean;
}

export function DataSourcesSelector({
  dataSources,
  setDataSources,
  single = false,
}: DataSourcesSelectorProps) {
  const datasourcesQuery = useAllDataSourcesQuery(true);

  const dataSourceOptions = useMemo(() => {
    return (
      datasourcesQuery.data
        ?.map((x) => {
          return {
            label: `${x.name}`,
            value: x,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label)) ?? []
    );
  }, [datasourcesQuery.data]);

  return (
    <>
      {single && (
        <Dropdown
          options={dataSourceOptions}
          value={dataSources[0]}
          onChange={(e) => {
            setDataSources([e.target.value as DataSource]);
          }}
          className="text-overflow-ellipsis max-w-full m-2"
          placeholder="Select data source"
        />
      )}
      {!single && (
        <MultiSelect
          options={dataSourceOptions}
          value={dataSources}
          onChange={(e) => {
            setDataSources(
              (e.target.value as DataSource[]).sort((a, b) => a.id - b.id)
            );
          }}
          className="text-overflow-ellipsis max-w-full m-2"
          placeholder="Select data sources"
          showSelectAll={false}
          scrollHeight="50vh"
          optionLabel="label"
          panelFooterTemplate={
            <>
              <hr />
              <div className="flex justify-start p-1 py-1">
                <Button
                  className="p-button-outlined p-button-text p-button-secondary w-full"
                  icon="pi pi-times"
                  label={"Clear selection"}
                  onClick={() => setDataSources([])}
                />
              </div>
            </>
          }
          loading={datasourcesQuery.isLoading}
        />
      )}
    </>
  );
}
