import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  useAllUsersQuery,
  useCreateUserMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
} from "../queries/users.query";
import { CenteredLoader } from "../components/CenteredLoader";
import { useToast } from "../components/ToastContext";
import { Button } from "primereact/button";
import { useState } from "react";
import { User } from "../queries/models/users/user.dto";
import { AddEditUser } from "../queries/models/users/add-edit-user.dto";
import { Dialog } from "primereact/dialog";
import { UserForm } from "../components/UserForm";
import { confirmDialog } from "primereact/confirmdialog";
import { useQueryClient } from "@tanstack/react-query";

export function Users() {
  const usersQuery = useAllUsersQuery();

  const [selectedUser, setSelectedUser] = useState<AddEditUser>();
  const [userToAdd, setUserToAdd] = useState<AddEditUser>();
  const [addUserDialogVisible, setAddUserDialogVisible] = useState(false);
  const [editUserDialogVisible, setEditUserDialogVisible] = useState(false);

  const toast = useToast();

  const createUserMutation = useCreateUserMutation();
  const deleteUserMutation = useDeleteUserMutation();
  const updateUserMutation = useUpdateUserMutation();
  const queryClient = useQueryClient();

  function showAddUserDialog() {
    setUserToAdd({
      email: "",
      roleId: 0,
    } as AddEditUser);
    setAddUserDialogVisible(true);
  }

  function showEditUserDialog(user: User) {
    setSelectedUser({
      id: user.id,
      email: user.email,
      roleId: user.roleId,
    } as AddEditUser);
    setEditUserDialogVisible(true);
  }

  function hideAddUserDialog() {
    setAddUserDialogVisible(false);
    setUserToAdd(undefined);
  }

  function hideEditUserDialog() {
    setEditUserDialogVisible(false);
    setSelectedUser(undefined);
  }

  function createUser(user: AddEditUser) {
    createUserMutation.mutate(user, {
      onSuccess: () => {
        hideAddUserDialog();
        toast.current?.show({
          severity: "success",
          detail: "Save Successful",
        });
        queryClient.invalidateQueries({ queryKey: ["users"] });
      },
      onError: (error) => {
        console.info("Error!", error);
        toast.current?.show({
          severity: "error",
          detail: "Save Failed",
        });
      },
    });
  }

  function deleteUser(user: AddEditUser) {
    console.info("Deleting user", user);
    confirmDialog({
      message: "Are you sure you want to delete this user?",
      header: "Delete",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        deleteUserMutation.mutate(user.id!, {
          onSuccess: () => {
            toast.current?.show({
              severity: "success",
              detail: "Delete Successful",
            });
            hideEditUserDialog();
            queryClient.invalidateQueries({ queryKey: ["users"] });
          },
          onError: (error) => {
            toast.current?.show({
              severity: "error",
              detail: "Delete Failed",
            });
          },
        });
      },
    });
  }

  function updateUser(user: AddEditUser) {
    updateUserMutation.mutate(user, {
      onSuccess: () => {
        hideEditUserDialog();
        toast.current?.show({
          severity: "success",
          detail: "Update Successful",
        });
        queryClient.invalidateQueries({ queryKey: ["users"] });
      },
      onError: (error) => {
        console.info("Error!", error);
        toast.current?.show({
          severity: "error",
          detail: "Update Failed",
        });
      },
    });
  }

  if (!usersQuery.data && usersQuery.isLoading) {
    return <CenteredLoader spinner />;
  }

  return (
    <div>
      <div className="flex m-4 justify-end">
        <Button
          label="Add User"
          icon="pi pi-plus"
          onClick={showAddUserDialog}
        />
      </div>
      {usersQuery.data && (
        <DataTable
          value={usersQuery.data}
          onRowSelect={(e) => showEditUserDialog(e.data)}
          selectionMode="single"
          selection={selectedUser}
        >
          <Column field="id" header="ID" />
          <Column field="email" header="Email" />
          <Column field="role" header="Role" />
        </DataTable>
      )}

      <Dialog
        visible={addUserDialogVisible}
        header="Add User"
        onHide={hideAddUserDialog}
      >
        {userToAdd && (
          <div className="flex flex-col">
            <UserForm
              initialValue={userToAdd}
              onSave={createUser}
              onDelete={deleteUser}
            />
          </div>
        )}
      </Dialog>

      <Dialog
        visible={editUserDialogVisible}
        header="Edit User"
        onHide={hideEditUserDialog}
      >
        {selectedUser && (
          <div className="flex flex-col">
            <UserForm
              isEdit
              initialValue={selectedUser as AddEditUser}
              onSave={updateUser}
              onDelete={deleteUser}
            />
          </div>
        )}
      </Dialog>
    </div>
  );
}
