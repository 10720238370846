import { useState } from "react";
import { DataSourcesSelector } from "../components/DataSourcesSelector";
import { DataSource } from "../queries/models/data-source.dto";
import { InputNumberWithLabel } from "../components/InputNumberWithLabel";
import CalendarPicker from "../components/CalendarPicker";
import { Nullable } from "primereact/ts-helpers";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import {
  useCreatePredictionMutation,
  useGetPredictionsQuery,
  useRerunPredictionMutation,
  useDeletePredictionMutation,
} from "../queries/predictions.query";
import { useToast } from "../components/ToastContext";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import { Prediction } from "../queries/models/prediction.dto";
import { PredictionStatus } from "../queries/models/prediction-status.enum";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { useQueryClient } from "@tanstack/react-query";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

export function Predictions() {
  const [dataSources, setDataSources] = useState<DataSource[]>([]);
  const [trainingSetDates, setTrainingSetDates] = useState<
    Nullable<Date | Date[]>
  >([new Date(), new Date()]);

  const [daysToPredict, setDaysToPredict] = useState<number>(1);

  const toast = useToast();
  const navigate = useNavigate();

  const createPredictionMutation = useCreatePredictionMutation();
  const rerunPredictionMutation = useRerunPredictionMutation();
  const deletePredictionMutation = useDeletePredictionMutation();
  const { data: predictions } = useGetPredictionsQuery(5000);
  const queryClient = useQueryClient();

  const handleCreatePrediction = () => {
    createPredictionMutation.mutate(
      {
        dataSourceId: dataSources[0].id,
        trainingSetStartDate: (trainingSetDates as Date[])[0],
        trainingSetEndDate: (trainingSetDates as Date[])[1],
        daysToPredict: daysToPredict,
      },
      {
        onSuccess(data, variables, context) {
          toast.current?.show({
            severity: "success",
            detail: "Prediction created",
          });
          queryClient.invalidateQueries({ queryKey: ["predictions"] });
        },
        onError(error, variables, context) {
          toast.current?.show({
            severity: "error",
            detail: "Failed to create prediction",
          });
          queryClient.invalidateQueries({ queryKey: ["predictions"] });
        },
      }
    );
  };

  function openDetails(predictionId: number) {
    navigate(`/predictions/${predictionId}`);
  }

  function rerunPrediction(predictionId: number) {
    rerunPredictionMutation.mutate(predictionId, {
      onSuccess(data, variables, context) {
        toast.current?.show({
          severity: "success",
          detail: "Prediction rerun started",
        });
        queryClient.invalidateQueries({ queryKey: ["predictions"] });
      },
      onError(error, variables, context) {
        toast.current?.show({
          severity: "error",
          detail: "Failed to rerun prediction",
        });
        queryClient.invalidateQueries({ queryKey: ["predictions"] });
      },
    });
  }

  function deletePrediction(predictionId: number) {
    confirmDialog({
      message: "Are you sure you want to delete this prediction?",
      header: "Delete",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        deletePredictionMutation.mutate(predictionId, {
          onSuccess(data, variables, context) {
            toast.current?.show({
              severity: "success",
              detail: "Prediction deleted",
            });
            queryClient.invalidateQueries({ queryKey: ["predictions"] });
          },
          onError(error, variables, context) {
            toast.current?.show({
              severity: "error",
              detail: "Failed to delete prediction",
            });
          },
        });
      },
    });
  }

  const statusBodyTemplate = (rowData: Prediction) => {
    if (rowData.status === PredictionStatus.Pending) {
      return <Tag severity="info" value={PredictionStatus[rowData.status]} />;
    }

    if (rowData.status === PredictionStatus.Running) {
      return (
        <Tag severity="warning" value={PredictionStatus[rowData.status]} />
      );
    }

    if (rowData.status === PredictionStatus.Completed) {
      return (
        <Tag severity="success" value={PredictionStatus[rowData.status]} />
      );
    }

    if (rowData.status === PredictionStatus.Failed) {
      return <Tag severity="danger" value={PredictionStatus[rowData.status]} />;
    }
  };

  function actionsBodyTemplate(rowData: Prediction) {
    return (
      <div className="flex gap-2">
        {rowData.status === PredictionStatus.Completed && (
          <Button
            label="Open"
            severity="success"
            onClick={() => openDetails(rowData.id)}
          />
        )}

        {(rowData.status === PredictionStatus.Failed ||
          rowData.status === PredictionStatus.Pending) && (
          <Button
            label={rowData.status === PredictionStatus.Failed ? "Rerun" : "Run"}
            severity="warning"
            onClick={() => rerunPrediction(rowData.id)}
          />
        )}

        <Button
          icon="pi pi-trash"
          severity="danger"
          onClick={() => deletePrediction(rowData.id)}
        />
      </div>
    );
  }

  return (
    <div className="p-4">
      <Card title="Create New Prediction" className="mb-4">
        <div className="flex-row">
          <label className="font-semibold text-md mb-1 py-2 text-gray-500">
            Data Source:
          </label>
          <DataSourcesSelector
            dataSources={dataSources}
            setDataSources={setDataSources}
            single={true}
          />
        </div>
        <div>
          <InputNumberWithLabel
            label="Number of days to predict: "
            value={daysToPredict}
            min={1}
            max={30}
            size={2}
            onChange={(e) => setDaysToPredict(e.value ?? 0)}
          />
        </div>
        <div className="md:col-span-2">
          <CalendarPicker
            value={trainingSetDates}
            setValue={setTrainingSetDates}
            label="Training set date range"
            single={false}
          />
        </div>
        <Divider />
        <div className="flex justify-end">
          <Button
            label="Add to queue"
            onClick={handleCreatePrediction}
            icon="pi pi-plus"
          />
        </div>
      </Card>

      <div>
        <h2 className="text-2xl font-bold mt-4">Predictions</h2>
        <DataTable value={predictions ?? []}>
          <Column field="dataSourceName" header="Data Source" />
          <Column
            field="trainingSetStartDate"
            header="Training Set Start Date"
            body={(row) => (
              <span>{format(row.trainingSetStartDate, "dd.MM.yyyy")}</span>
            )}
          />
          <Column
            field="trainingSetEndDate"
            header="Training Set End Date"
            body={(row) => (
              <span>{format(row.trainingSetEndDate, "dd.MM.yyyy")}</span>
            )}
          />
          <Column
            field="predictionStartDate"
            header="Prediction Start Date"
            body={(row) => (
              <span>{format(row.predictionStartDate, "dd.MM.yyyy")}</span>
            )}
          />
          <Column field="daysToPredict" header="Days to Predict" />
          <Column header="Status" body={statusBodyTemplate} />
          <Column header="Actions" body={actionsBodyTemplate} />
        </DataTable>
      </div>
      <ConfirmDialog />
    </div>
  );
}
