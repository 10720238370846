import { useEffect, useState } from "react";
import { LoggedUserInfo } from "../auth/logged-user-info";
import { handleDates } from "../utils/serialize-date-helpers";

export type KeyTypes = {
  AUTH_USER_INFO: LoggedUserInfo | undefined;
  PREFERENCES_SIDE_MENU_IS_COLLAPSED: boolean;
  THEME: "dark" | "light";
};

type Subscriber = {
  id: number;
  key: keyof KeyTypes;
  callback: (value: KeyTypes[keyof KeyTypes] | undefined) => void;
};
export namespace LocalStorageService {
  const memoizedValues: { [key in keyof KeyTypes]?: KeyTypes[key] } = {};

  let subLastId = 0;
  const subscribers: Subscriber[] = [];

  export function unsubscribe(subscriptionId: number) {
    return () => {
      const index = subscribers.findIndex((x) => x.id === subscriptionId);
      subscribers.splice(index, 1);
    };
  }

  export function get<T extends keyof KeyTypes>(
    key: T,
    options?: {
      getMemoizedValue?: boolean;
      setMemoizedValue?: boolean;
    }
  ): KeyTypes[T] | undefined {
    let result: KeyTypes[T] | undefined = undefined;
    if (options?.getMemoizedValue !== false) {
      result = memoizedValues[key];
    }
    if (result === undefined) {
      const value = localStorage.getItem(key);
      if (!value) return undefined;
      try {
        result = JSON.parse(value);
        handleDates(result);
      } catch {
        return undefined;
      }
      if (options?.setMemoizedValue !== false) {
        memoizedValues[key] = result;
      }
    }
    return result;
  }

  export function set<T extends keyof KeyTypes>(
    key: T,
    value: KeyTypes[T] | undefined,
    options?: {
      setMemoizedValue?: boolean;
    }
  ) {
    const newValue = memoizedValues[key] !== value;
    if (options?.setMemoizedValue !== false) {
      memoizedValues[key] = value;
    }
    if (value !== undefined) {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.removeItem(key);
    }
    if (newValue) {
      subscribers
        .filter((x) => x.key === key)
        .forEach((x) => {
          x.callback(value);
        });
    }
  }

  export function subscribe<T extends keyof KeyTypes>(
    key: T,
    callback: (value: KeyTypes[T] | undefined) => void
  ) {
    ++subLastId;
    subscribers.push({
      key,
      callback: callback as any,
      id: subLastId,
    });

    return {
      unsubscribe: unsubscribe(subLastId),
    };
  }

  export function useKey<T extends keyof KeyTypes>(key: T) {
    const [value, setValue] = useState<KeyTypes[T] | undefined>(
      LocalStorageService.get(key)
    );

    useEffect(() => {
      const sub = LocalStorageService.subscribe(key, (val) => {
        setValue(val as any);
      });
      return () => {
        sub.unsubscribe();
      };
    }, [key]);

    const setter = (val: KeyTypes[T]) => {
      LocalStorageService.set(key, val);
    };

    return [value, setter] as const;
  }
}
