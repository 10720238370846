import { useMemo, useReducer } from "react";
import { AddEditUser } from "../queries/models/users/add-edit-user.dto";
import { modelReducer } from "../utils/forms";
import { InputText } from "primereact/inputtext";
import { ContentWithLabel } from "./ContentWithLabel";
import { FormWrapper } from "./FormWrapper";
import { useGetRolesQuery } from "../queries/users.query";
import { Dropdown } from "primereact/dropdown";

interface UserFormProps {
  initialValue: AddEditUser;
  isEdit?: boolean;
  onSave: (user: AddEditUser) => any;
  onDelete?: (user: AddEditUser) => any;
}

export function UserForm({
  initialValue,
  isEdit,
  onSave,
  onDelete,
}: UserFormProps) {
  const [value, updateValues] = useReducer(
    modelReducer<AddEditUser>,
    initialValue
  );

  const rolesQuery = useGetRolesQuery();

  const roleOptions = useMemo(
    () =>
      rolesQuery.data?.map((role) => ({
        label: role.name,
        value: role.id,
      })) ?? [],
    [rolesQuery.data]
  );

  return (
    <FormWrapper
      onSave={() => onSave(value)}
      onDelete={() => onDelete?.(value)}
    >
      <ContentWithLabel label="Email">
        <InputText
          value={value.email}
          type="email"
          onChange={(v) => updateValues({ email: v.target.value })}
        />
      </ContentWithLabel>
      <ContentWithLabel label="Password">
        <InputText
          value={value.password ?? ""}
          placeholder={isEdit ? "Leave empty to keep current password" : ""}
          type="password"
          autoComplete="new-password"
          onChange={(v) => updateValues({ password: v.target.value })}
        />
      </ContentWithLabel>
      <ContentWithLabel label="Role">
        <Dropdown
          value={value.roleId}
          options={roleOptions}
          onChange={(v) => updateValues({ roleId: v.value })}
        />
      </ContentWithLabel>
    </FormWrapper>
  );
}
