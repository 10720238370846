import { useState, useEffect, useMemo } from "react";
import { Site } from "../queries/models/site.dto";
import {
  useAllSitesQuery,
  useCreateSiteMutation,
  useDeleteSiteMutation,
  useUpdateSiteMutation,
} from "../queries/sites.query";
import { useToast } from "../components/ToastContext";
import { SiteStatus } from "../queries/models/site-status.enum";
import { CenteredLoader } from "../components/CenteredLoader";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { SiteForm } from "../components/SiteForm";
import { SiteCategory } from "../queries/models/site-category.enum";
import { ConditionalCheckmark } from "../components/ConditionalCheckmark";
import { FilterMatchMode } from "primereact/api";
import { Dropdown } from "primereact/dropdown";
import { confirmDialog } from "primereact/confirmdialog";
import { enumToDropdownOptions } from "../utils/forms";
import {
  asDataTableFilterOptions,
  useDataTableLocalFilters,
} from "../hooks/use-data-table-local-filters";

const SitesDataTableFilters = asDataTableFilterOptions({
  useUrlParams: true,
  dataTableSchema: {
    sparkiCode: {
      matchMode: FilterMatchMode.CONTAINS,
    },
    address: {
      matchMode: FilterMatchMode.CONTAINS,
    },
    ean: {
      matchMode: FilterMatchMode.CONTAINS,
    },
    isShop: { matchMode: FilterMatchMode.EQUALS },
    category: { matchMode: FilterMatchMode.EQUALS },
    chargerType: {
      matchMode: FilterMatchMode.CONTAINS,
    },
    status: { matchMode: FilterMatchMode.EQUALS },
  },
});

export function Sites() {
  const [selectedSite, setSelectedSite] = useState<Site>();
  const [siteToAdd, setSiteToAdd] = useState<Site>();

  const [editSiteDialogVisible, setEditSiteModalVisible] = useState(false);
  const [addSiteDialogVisible, setAddSiteModalVisible] = useState(false);

  const sitesQuery = useAllSitesQuery();
  const updateSiteMutation = useUpdateSiteMutation();
  const createSiteMutation = useCreateSiteMutation();
  const deleteSiteMutation = useDeleteSiteMutation();

  const toast = useToast();

  const [first, setFirst] = useState(0);

  const {
    dataTableFilters,
    onDataTableFilter,
    dataTableSortField,
    dataTableSortOrder,
    onDataTableSort,
  } = useDataTableLocalFilters(SitesDataTableFilters);

  const getFullAddress = (site: Site) => {
    if (!site.postCode || !site.city) {
      return "";
    }

    return `${site.street} ${site.number} ${site.bus || ""}, ${site.postCode} ${
      site.city
    }`.trim();
  };

  const sitesForTable = useMemo(() => {
    return sitesQuery.data?.map((site) => ({
      ...site,
      address: getFullAddress(site),
    }));
  }, [sitesQuery.data]);

  function showSiteDialog(site: Site) {
    setSelectedSite(site);
    setEditSiteModalVisible(true);
  }

  function showSiteAddDialog() {
    setSiteToAdd({
      id: undefined!,
      sparkiCode: "",
      city: "",
      street: "",
      number: "",
      bus: "",
      postCode: "",
      ean: "",
      chargerType: "",
      category: SiteCategory.None,
      isShop: false,
      status: SiteStatus.None,
    } as Site);
    setAddSiteModalVisible(true);
  }

  function hideEditSiteDialog() {
    setEditSiteModalVisible(false);
    setSelectedSite(undefined);
  }

  function hideAddSiteDialog() {
    setAddSiteModalVisible(false);
    setSiteToAdd(undefined);
  }

  async function saveSite(site: Site) {
    updateSiteMutation.mutate(site, {
      onSuccess: async () => {
        hideEditSiteDialog();
        toast.current?.show({
          severity: "success",
          detail: "Save Successful",
        });
      },
      onError: (error: any) => {
        console.info("Error!", error);
        toast.current?.show({
          severity: "error",
          detail: "Save Failed",
        });
      },
    });
  }

  async function createSite(site: Site) {
    createSiteMutation.mutateAsync(site).then(
      () => {
        hideAddSiteDialog();
        toast.current?.show({
          severity: "success",
          detail: "Save Successful",
        });
      },
      (error) => {
        toast.current?.show({
          severity: "error",
          detail: "Save Failed",
        });
      }
    );
  }

  async function deleteSite(site: Site) {
    confirmDialog({
      message: "Are you sure you want to delete this site?",
      header: "Delete",
      icon: "pi pi-exclamation-triangle",
      accept: () => {
        deleteSiteMutation.mutateAsync(site.id).then(
          () => {
            hideEditSiteDialog();
            toast.current?.show({
              severity: "success",
              detail: "Deletion Successful",
            });
          },
          (error) => {
            toast.current?.show({
              severity: "error",
              detail: "Deletion Failed",
            });
          }
        );
      },
    });
  }

  if (!sitesForTable && sitesQuery.isLoading) {
    return (
      <div>
        <CenteredLoader spinner />
      </div>
    );
  }

  return (
    <div>
      <div className="flex m-4 justify-end">
        <Button label={"Add Site"} onClick={showSiteAddDialog} />
      </div>
      <DataTable
        value={sitesForTable}
        selectionMode="single"
        selection={selectedSite}
        sortMode="single"
        sortField={dataTableSortField}
        sortOrder={dataTableSortOrder}
        onSort={onDataTableSort}
        removableSort
        onRowClick={(e) => showSiteDialog(e.data as Site)}
        paginator
        rows={15}
        first={first}
        onPage={(e) => setFirst(e.first)}
        filters={dataTableFilters}
        onFilter={onDataTableFilter}
        filterDisplay="menu"
        emptyMessage="No sites found."
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
      >
        <Column
          header="Sparki code"
          field="sparkiCode"
          sortable
          filter
          showFilterMatchModes={false}
        />
        <Column
          header="Address"
          field="address"
          sortable
          sortField="address"
          filter
          showFilterMatchModes={false}
        />
        <Column
          header="EAN"
          field="ean"
          sortable
          filter
          showFilterMatchModes={false}
        />
        <Column
          header="Shop"
          field="isShop"
          body={(x: Site) => (
            <div className="flex w-full justify-center items-center">
              <ConditionalCheckmark show={x.isShop} />
            </div>
          )}
          sortable
          sortField="isShop"
          sortFunction={(event) => {
            const { data, order } = event;
            return data.sort(
              (a: Site, b: Site) =>
                (order ?? 0) * ((a.isShop ? 1 : -1) - (b.isShop ? 1 : -1))
            );
          }}
          filter
          filterElement={(options) => (
            <Dropdown
              value={options.value}
              options={[
                { label: "Yes", value: true },
                { label: "No", value: false },
              ]}
              onChange={(e) => options.filterCallback(e.value)}
              placeholder="Select"
              className="w-full"
            />
          )}
          showFilterMatchModes={false}
        />
        <Column
          header="Category"
          body={(x: Site) => SiteCategory[x.category ?? SiteCategory.None]}
          field="category"
          sortable
          sortField="category"
          sortFunction={(event) => {
            const { data, order } = event;
            return data.sort((a: Site, b: Site) => {
              return (
                (order ?? 0) *
                (
                  SiteCategory[a.category ?? SiteCategory.None] ?? ""
                ).localeCompare(
                  SiteCategory[b.category ?? SiteCategory.None] ?? ""
                )
              );
            });
          }}
          filter
          filterElement={(options) => (
            <Dropdown
              value={options.value}
              options={enumToDropdownOptions(SiteCategory)}
              onChange={(e) => options.filterCallback(e.value)}
              placeholder="Select a category"
            />
          )}
          showFilterMatchModes={false}
        />
        <Column header="Charger type" field="chargerType" sortable filter />
        <Column
          header="Status"
          field="status"
          body={(x: Site) => SiteStatus[x.status ?? SiteStatus.None]}
          sortable
          sortField="status"
          sortFunction={(event) => {
            const { data, order } = event;
            return data.sort((a: Site, b: Site) => {
              return (
                (order ?? 0) *
                (SiteStatus[a.status ?? SiteStatus.None] ?? "").localeCompare(
                  SiteStatus[b.status ?? SiteStatus.None] ?? ""
                )
              );
            });
          }}
          filter
          filterElement={(options) => (
            <Dropdown
              value={options.value}
              options={enumToDropdownOptions(SiteStatus)}
              onChange={(e) => options.filterCallback(e.value)}
              placeholder="Select a status"
            />
          )}
          showFilterMatchModes={false}
        />
      </DataTable>

      <Dialog
        visible={editSiteDialogVisible}
        header={"Site"}
        onHide={() => hideEditSiteDialog()}
      >
        {selectedSite && (
          <div className="flex flex-col">
            <SiteForm
              initialValue={selectedSite}
              onSave={saveSite}
              onDelete={deleteSite}
            />
          </div>
        )}
      </Dialog>

      <Dialog
        visible={addSiteDialogVisible}
        header={"Add site"}
        onHide={() => hideAddSiteDialog()}
      >
        {siteToAdd && (
          <div className="flex flex-col">
            <SiteForm initialValue={siteToAdd} onSave={createSite} />
          </div>
        )}
      </Dialog>
    </div>
  );
}
