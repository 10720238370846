import { useQuery } from "@tanstack/react-query";
import { dataAccessService } from "../services/data-access.service";

export function useDataQuery(dataSourcesIds: number[], from: Date, to: Date) {
  return useQuery({
    queryKey: ["data-access", { dataSourcesIds, from, to }],
    queryFn: () => dataAccessService.getData(dataSourcesIds, from, to),
    enabled: Boolean(dataSourcesIds.length && from && to),
  });
}
