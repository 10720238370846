export function ContentWithLabel({
  label,
  children,
}: {
  label: string;
  children: React.ReactNode;
}) {
  return (
    <>
      <label className="mt-2">{label}</label>
      {children}
    </>
  );
}
