import { SideBarElementDesktop } from "./SideBarElementDesktop";
import { MenuElement } from "./SideBarNavigation";

export function SideBarElementsDesktop({
  menuElements,
  sideBarExpanded,
}: {
  menuElements: MenuElement[];
  sideBarExpanded: boolean;
}) {
  return (
    <ul className="list-none align-self-center w-full">
      {menuElements.map((x, i) => (
        <SideBarElementDesktop
          key={i}
          to={x.to}
          label={x.label}
          icon={x.icon}
          sideBarExpanded={sideBarExpanded}
        />
      ))}
    </ul>
  );
}
