import { Navigate, Route, Routes } from "react-router";
import { useAuth } from "./auth/AuthProvider";
import { AxiosInterceptor } from "./components/AxiosInterceptorsWrapper";
import { TopBarNavigation } from "./components/TopBarNavigation";
import { SideBarNavigation } from "./components/SideBarNavigation";
import { classNames } from "primereact/utils";
import { DataCharts } from "./pages/DataCharts";
import { DataSources } from "./pages/DataSources";
import { Sites } from "./pages/Sites";
import { LocalStorageService } from "./services/local-storage.service";
import { Predictions } from "./pages/Predictions";
import { PredictionDetails } from "./pages/PredictionDetails";
import { Users } from "./pages/Users";
import { ConfirmDialog } from "primereact/confirmdialog";

export function MainApp() {
  const auth = useAuth();

  const [sideBarCollapsed] = LocalStorageService.useKey(
    "PREFERENCES_SIDE_MENU_IS_COLLAPSED"
  );

  console.info(auth.userData);

  if (!auth.userData?.accessToken) {
    return <Navigate to="/login" />;
  }

  return (
    <AxiosInterceptor>
      <TopBarNavigation></TopBarNavigation>
      <SideBarNavigation></SideBarNavigation>
      <div className="pt-14 justify-content-end flex">
        <div
          className={classNames(
            "w-full transition-duration-150",
            !sideBarCollapsed
              ? "lg:pl-56 pl-0"
              : "w-full lg:pl-20 pr-0 lg:pr-1 pl-0 lg:pb-0 pb-12"
          )}
        >
          <Routes>
            <Route path="/" element={<Navigate to="sites" />} />
            <Route path="data-charts" element={<DataCharts />} />
            <Route path="sites" element={<Sites />} />
            <Route path="data-sources" element={<DataSources />} />
            <Route path="predictions" element={<Predictions />} />
            <Route path="predictions/:id" element={<PredictionDetails />} />
            <Route path="users" element={<Users />} />
          </Routes>
        </div>
      </div>
      <ConfirmDialog />
    </AxiosInterceptor>
  );
}
