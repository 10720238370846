import { SideBarElementMobile } from "./SideBarElementMobile";
import { faEllipsis } from "@fortawesome/free-solid-svg-icons";
import { Sidebar } from "primereact/sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWindowSize } from "../hooks/use-window-size";
import { useEffect, useState } from "react";
import { MenuElement } from "./SideBarNavigation";

const pixelsPerItem = 125;

export function SideBarElementsMobile({
  menuElements,
}: {
  menuElements: MenuElement[];
}) {
  const [visibleRight, setVisibleRight] = useState(false);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (visibleRight) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [visibleRight]);

  return (
    <>
      <div></div>
      <ul className="list-none px-3 flex justify-between">
        {menuElements
          .filter((_, i) => pixelsPerItem * (i + 1) < windowSize.width)
          .map((x, i) => (
            <SideBarElementMobile to={x.to} label={x.label} icon={x.icon} />
          ))}
        {windowSize.width < pixelsPerItem * menuElements.length && (
          <li onClick={() => setVisibleRight(true)} className="grow basis-0">
            <div className="text-center flex flex-col items-center no-underline text-sm">
              <FontAwesomeIcon
                icon={faEllipsis}
                className="text-xl text-gray-600"
              />
              <div className="text-gray-600 text-sm">More</div>
            </div>
          </li>
        )}
      </ul>
      <div className="card">
        <Sidebar
          className="!w-40"
          ariaCloseLabel="true"
          visible={visibleRight}
          position="right"
          onHide={() => setVisibleRight(false)}
        >
          <div className="flex justify-between flex-col h-full">
            <ul>
              {menuElements
                .filter((_, i) => pixelsPerItem * (i + 1) >= windowSize.width)
                .map((x, i) => (
                  <div
                    key={i}
                    onClick={() => setVisibleRight(false)}
                    className="py-3"
                  >
                    <SideBarElementMobile
                      to={x.to}
                      label={x.label}
                      icon={x.icon}
                    />
                  </div>
                ))}
            </ul>
          </div>
        </Sidebar>
      </div>
    </>
  );
}
