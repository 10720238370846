import { useRef } from "react";
import "./App.css";
import { Toast } from "primereact/toast";
import { locale, addLocale, PrimeReactProvider } from "primereact/api";
import { ToastContextProvider } from "./components/ToastContext";
import { Route, Routes } from "react-router";
import { AuthProvider } from "./auth/AuthProvider";
import { Tooltip } from "primereact/tooltip";
import { MainApp } from "./MainApp";
import { Login } from "./pages/Login";
import { LocalStorageService } from "./services/local-storage.service";

export function getSystemTheme() {
  return window.matchMedia?.("(prefers-color-scheme: dark)")?.matches
    ? "dark"
    : "light";
}

export function swapThemes() {
  const currentTheme = LocalStorageService.get("THEME") ?? getSystemTheme();
  try {
    for (const styleSheets of (document as any).styleSheets) {
      for (const cssRules of styleSheets.cssRules) {
        if (cssRules instanceof CSSMediaRule) {
          (cssRules as any).media = cssRules.media
            .toString()
            .replace(/prefers-color-scheme: ?(dark|light)/, (substr) =>
              substr.includes("dark")
                ? "prefers-color-scheme: light"
                : "prefers-color-scheme: dark"
            );
        }
      }
    }
  } catch {
    console.error("Failed to change css themes");
  }

  LocalStorageService.set("THEME", currentTheme === "dark" ? "light" : "dark");
}

if (
  LocalStorageService.get("THEME") !== undefined &&
  LocalStorageService.get("THEME") !== getSystemTheme()
) {
  // has to adjust theme for swapping
  LocalStorageService.set("THEME", getSystemTheme());
  swapThemes();
}

function App() {
  const toast = useRef<Toast>(null);

  addLocale("nl", {
    firstDayOfWeek: 1,
    dateFormat: "dd.mm.yy",
  });
  locale("nl");

  return (
    <>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no"
      />
      <Toast ref={toast} />
      <Tooltip
        target=".custom-tooltip"
        hideDelay={32}
      />

      <ToastContextProvider toastRef={toast}>
        <PrimeReactProvider>
          <AuthProvider>
            <Routes>
              <Route
                path="login"
                element={<Login />}
              />
              <Route
                path="*"
                element={<MainApp />}
              />
            </Routes>
          </AuthProvider>
        </PrimeReactProvider>
      </ToastContextProvider>
    </>
  );
}

export default App;
