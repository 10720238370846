import { DataSource } from "../queries/models/data-source.dto";
import { API_ENDPOINTS } from "../utils/endpoints";
import { BaseService } from "./base.service";

class DataSourcesService extends BaseService {
  async getAll(onlyActive: boolean = false): Promise<DataSource[]> {
    const url = `${this.endpointPath}`;
    return this.http
      .get<DataSource[]>(url, { params: { onlyActive } })
      .then((res) => res.data);
  }

  async getById(id: number): Promise<DataSource> {
    const url = `${this.endpointPath}/${id}`;
    return this.http.get<DataSource>(url).then((res) => res.data);
  }

  async delete(id: number) {
    const url = `${this.endpointPath}/${id}`;
    return this.http.delete(url);
  }

  async create(dataSource: DataSource): Promise<DataSource> {
    const url = `${this.endpointPath}`;
    return this.http.post(url, dataSource).then((x) => x.data);
  }

  async update(dataSource: DataSource): Promise<DataSource> {
    const url = `${this.endpointPath}/${dataSource.id}`;
    return this.http.put(url, dataSource).then((x) => x.data);
  }
}

export const dataSourcesService = new DataSourcesService(
  API_ENDPOINTS.DATA_SOURCES
);
