import { ToggleButton } from "primereact/togglebutton";
import { classNames } from "primereact/utils";
import {
  QueryFilters,
  useIsFetching,
  useQueryClient,
} from "@tanstack/react-query";

export interface RefreshButtonProps {
  queryFilters: QueryFilters;
  className?: string;
}

export function RefreshButton({ queryFilters, className }: RefreshButtonProps) {
  const queryClient = useQueryClient();
  const isFetching = useIsFetching(queryFilters, queryClient);

  return (
    <>
      <ToggleButton
        className={classNames("border-circle h-12 w-12", className)}
        checked={isFetching > 0}
        onIcon="!font-bold !text-blue-700 pi pi-spin pi-sync"
        onLabel=""
        offIcon="!font-bold !text-blue-700 pi pi-sync"
        offLabel=""
        onChange={() => {
          if (isFetching) return;
          queryClient.invalidateQueries(queryFilters);
        }}
      />
    </>
  );
}
