import { useState } from "react";
import { useToast } from "../components/ToastContext";
import { CenteredLoader } from "../components/CenteredLoader";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { DataSource } from "../queries/models/data-source.dto";
import {
  useAllDataSourcesQuery,
  useCreateDataSourceMutation,
  useDeleteDataSourceMutation,
  useUpdateDataSourceMutation,
} from "../queries/data-sources.query";
import { DataSourceForm } from "../components/DataSourceForm";

export function DataSources() {
  const [selectedDataSource, setSelectedDataSource] = useState<DataSource>();
  const [dataSourceToAdd, setDataSourceToAdd] = useState<DataSource>();

  const [editDataSourceDialogVisible, setEditDataSourceModalVisible] =
    useState(false);
  const [addDataSourceDialogVisible, setAddDataSourceModalVisible] =
    useState(false);

  const dataSourcesQuery = useAllDataSourcesQuery();
  const updateDataSourceMutation = useUpdateDataSourceMutation();
  const createDataSourceMutation = useCreateDataSourceMutation();
  const deleteDataSourceMutation = useDeleteDataSourceMutation();

  const toast = useToast();

  function showDataSourceDialog(DataSource: DataSource) {
    setSelectedDataSource(DataSource);
    setEditDataSourceModalVisible(true);
  }

  function showDataSourceAddDialog() {
    setDataSourceToAdd({
      id: undefined!,
      name: "",
      timeSeriesChildDataSourceIds: [],
      siteId: undefined,
      timeserieDataSourceId: undefined!,
      specialType: 0,
      unit: null,
    } as DataSource);
    setAddDataSourceModalVisible(true);
  }

  function hideEditDataSourceDialog() {
    setEditDataSourceModalVisible(false);
    setSelectedDataSource(undefined);
  }

  function hideAddDataSourceDialog() {
    setAddDataSourceModalVisible(false);
    setDataSourceToAdd(undefined);
  }

  async function saveDataSource(DataSource: DataSource) {
    updateDataSourceMutation.mutate(DataSource, {
      onSuccess: async () => {
        hideEditDataSourceDialog();
        toast.current?.show({
          severity: "success",
          detail: "Save Successful",
        });
      },
      onError: (error: any) => {
        console.info("Error!", error);
        toast.current?.show({
          severity: "error",
          detail: "Save failed",
        });
      },
    });
  }

  async function createDataSource(DataSource: DataSource) {
    createDataSourceMutation.mutateAsync(DataSource).then(
      () => {
        hideAddDataSourceDialog();
        toast.current?.show({
          severity: "success",
          detail: "Save Successful",
        });
      },
      (error) => {
        console.info("Error!", error);
        toast.current?.show({
          severity: "error",
          detail: "Save failed",
        });
      }
    );
  }

  async function deleteDataSource(DataSource: DataSource) {
    deleteDataSourceMutation.mutateAsync(DataSource.id).then(
      () => {
        hideEditDataSourceDialog();
        toast.current?.show({
          severity: "success",
          detail: "Deletion Successful",
        });
      },
      (error) => {
        console.info("Error!", error);
        toast.current?.show({
          severity: "error",
          detail: "Deletion failed",
        });
      }
    );
  }

  if (!dataSourcesQuery.data && dataSourcesQuery.isLoading) {
    return (
      <div>
        <CenteredLoader spinner />
      </div>
    );
  }

  return (
    <div>
      <div className="flex m-4 justify-end">
        <Button label={"Add DataSource"} onClick={showDataSourceAddDialog} />
      </div>
      <DataTable
        value={dataSourcesQuery.data}
        selectionMode="single"
        selection={selectedDataSource}
        onRowClick={(e) => showDataSourceDialog(e.data as DataSource)}
      >
        <Column header="ID" field="id" />
        <Column header="Name" field="name" filter />
        <Column
          header="Sources"
          body={(x: DataSource) => {
            return `(${x.timeSeriesChildDataSourceIds?.length ?? 0})`;
          }}
        />
        <Column
          header="Special Type"
          field="specialType"
          body={(x: DataSource) => {
            return x.specialType !== 0 ? x.specialType : "";
          }}
        />
      </DataTable>

      <Dialog
        visible={editDataSourceDialogVisible}
        header={"DataSource"}
        onHide={() => hideEditDataSourceDialog()}
      >
        {selectedDataSource && (
          <div className="flex flex-col">
            <DataSourceForm
              initialValue={selectedDataSource}
              onSave={saveDataSource}
              onDelete={deleteDataSource}
              isEdit={true}
            />
          </div>
        )}
      </Dialog>

      <Dialog
        visible={addDataSourceDialogVisible}
        header={"Add DataSource"}
        onHide={() => hideAddDataSourceDialog()}
      >
        {dataSourceToAdd && (
          <div className="flex flex-col">
            <DataSourceForm
              initialValue={dataSourceToAdd}
              onSave={createDataSource}
            />
          </div>
        )}
      </Dialog>
    </div>
  );
}
