import { faMoon } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { swapThemes } from "../App";

export function ThemeSwitcher() {
  return (
    <div
      className="mr-5 flex rounded-full bg-black dark:bg-white text-white dark:text-black w-8 h-8 justify-center items-center cursor-pointer"
      onClick={swapThemes}
    >
      <FontAwesomeIcon
        icon={faMoon}
        size="lg"
        className="pointer-cursor"
      />
    </div>
  );
}
