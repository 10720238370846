import { Site } from "../queries/models/site.dto";
import { API_ENDPOINTS } from "../utils/endpoints";
import { BaseService } from "./base.service";

class SitesService extends BaseService {
  async getAll(): Promise<Site[]> {
    const url = `${this.endpointPath}`;
    return this.http.get<Site[]>(url).then((res) => res.data);
  }

  async getById(id: number): Promise<Site> {
    const url = `${this.endpointPath}/${id}`;
    return this.http.get<Site>(url).then((res) => res.data);
  }

  async delete(id: number) {
    const url = `${this.endpointPath}/${id}`;
    return this.http.delete(url);
  }

  async create(site: Site): Promise<Site> {
    const url = `${this.endpointPath}`;
    return this.http.post<Site>(url, site).then((x) => x.data);
  }

  async update(site: Site): Promise<Site> {
    const url = `${this.endpointPath}/${site.id}`;
    return this.http.put<Site>(url, site).then((x) => x.data);
  }
}

export const sitesService = new SitesService(API_ENDPOINTS.SITES);
