import { CreatePredictionDto } from "../queries/models/create-prediction.dto";
import { API_ENDPOINTS } from "../utils/endpoints";

import { BaseService } from "./base.service";

class PredictionsService extends BaseService {
  async create(prediction: CreatePredictionDto) {
    const url = `${this.endpointPath}`;
    return this.http.post(url, prediction).then((res) => res.data);
  }

  async getPredictions() {
    const url = `${this.endpointPath}`;
    return this.http.get(url).then((res) => res.data);
  }

  async getPrediction(predictionId: number) {
    const url = `${this.endpointPath}/${predictionId}`;
    return this.http.get(url).then((res) => res.data);
  }

  async rerun(predictionId: number) {
    const url = `${this.endpointPath}/${predictionId}/run`;
    return this.http.post(url, {}).then((res) => res.data);
  }

  async delete(predictionId: number) {
    const url = `${this.endpointPath}/${predictionId}`;
    return this.http.delete(url).then((res) => res.data);
  }
}

export const predictionsService = new PredictionsService(
  API_ENDPOINTS.PREDICTIONS
);
