export function modelReducer<T>(
  state: T,
  action: { [key in keyof T]?: T[key] }
): T {
  return {
    ...state,
    ...action,
  };
}

export function enumToDropdownOptions(enumObj: any) {
  return Object.entries(enumObj)
    .filter(([key, value]) => !isNaN(Number(value)))
    .map(([key, value]) => ({
      label: key,
      value: Number(value),
    }));
}
