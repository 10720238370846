import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { sitesService } from "../services/sites.service";
import { Site } from "./models/site.dto";

export function useAllSitesQuery() {
  return useQuery({
    queryKey: ["sites"],
    queryFn: () => sitesService.getAll(),
  });
}

export function useSiteQuery(id: number | undefined) {
  return useQuery({
    queryKey: ["sites", id],
    queryFn: () => sitesService.getById(id!),
    enabled: id !== undefined,
  });
}

export function useCreateSiteMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (site: Site) => sitesService.create(site),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["sites"],
      });
    },
  });
}

export function useDeleteSiteMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) => sitesService.delete(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["sites"],
      });
    },
  });
}

export function useUpdateSiteMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (site: Site) => sitesService.update(site),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["sites"],
      });
    },
  });
}
