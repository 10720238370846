import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { DataSource } from "./models/data-source.dto";
import { dataSourcesService } from "../services/data-sources.service";

export function useAllDataSourcesQuery(onlyActive: boolean = false) {
  return useQuery({
    queryKey: ["dataSources", onlyActive],
    queryFn: () => dataSourcesService.getAll(onlyActive),
  });
}

export function useDataSourceQuery(id: number | undefined) {
  return useQuery({
    queryKey: ["dataSources", id],
    queryFn: () => dataSourcesService.getById(id!),
    enabled: id !== undefined,
  });
}

export function useCreateDataSourceMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (dataSource: DataSource) =>
      dataSourcesService.create(dataSource),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["dataSources"],
      });
    },
  });
}

export function useDeleteDataSourceMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) => dataSourcesService.delete(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["dataSources"],
      });
    },
  });
}

export function useUpdateDataSourceMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (dataSource: DataSource) =>
      dataSourcesService.update(dataSource),
    onSuccess: (_, dataSource) => {
      queryClient.invalidateQueries({
        queryKey: ["dataSources", dataSource.id],
      });
    },
  });
}
