import { useReducer } from "react";
import { Site } from "../queries/models/site.dto";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { SiteStatus } from "../queries/models/site-status.enum";
import { Splitter } from "primereact/splitter";
import { SiteCategory } from "../queries/models/site-category.enum";
import { Checkbox } from "primereact/checkbox";
import { ContentWithLabel } from "./ContentWithLabel";
import { modelReducer } from "../utils/forms";

const siteStatusOptions = Object.values(SiteStatus)
  .filter((x) => typeof x === "string")
  .map((x) => {
    return {
      label: x,
      value: SiteStatus[x as any],
    };
  });

const siteCategoryOptions = Object.values(SiteCategory)
  .filter((x) => typeof x === "string")
  .map((x) => {
    return { label: x, value: SiteCategory[x as any] };
  });

export function SiteForm({
  initialValue,
  onSave,
  onDelete,
}: {
  initialValue: Site;
  onSave: (site: Site) => any;
  onDelete?: (site: Site) => any;
}) {
  const [value, updateValues] = useReducer(modelReducer<Site>, initialValue);

  return (
    <div className="flex flex-col grey-inputs" style={{ width: 600 }}>
      <ContentWithLabel label="Sparki code">
        <InputText
          value={value.sparkiCode}
          onChange={(v) => updateValues({ sparkiCode: v.target.value })}
          required
        />
      </ContentWithLabel>

      <Splitter className="mt-4" />

      <ContentWithLabel label="City">
        <InputText
          value={value.city}
          onChange={(v) => updateValues({ city: v.target.value })}
          required
        />
      </ContentWithLabel>

      <ContentWithLabel label="PostCode">
        <InputText
          value={value.postCode}
          onChange={(v) => updateValues({ postCode: v.target.value })}
          required
        />
      </ContentWithLabel>

      <ContentWithLabel label="Street">
        <InputText
          value={value.street}
          onChange={(v) => updateValues({ street: v.target.value })}
          required
        />
      </ContentWithLabel>

      <ContentWithLabel label="Number">
        <InputText
          value={value.number}
          onChange={(v) => updateValues({ number: v.target.value })}
          required
        />
      </ContentWithLabel>

      <ContentWithLabel label="Bus">
        <InputText
          value={value.bus}
          onChange={(v) => updateValues({ bus: v.target.value })}
        />
      </ContentWithLabel>

      <Splitter className="mt-4" />

      <ContentWithLabel label="EAN">
        <InputText
          value={value.ean}
          onChange={(v) => updateValues({ ean: v.target.value })}
        />
      </ContentWithLabel>

      <ContentWithLabel label="Is shop?">
        <Checkbox
          checked={value.isShop}
          onChange={(v) => updateValues({ isShop: v.checked })}
        />
      </ContentWithLabel>

      <ContentWithLabel label="Charger type">
        <InputText
          value={value.chargerType}
          onChange={(v) => updateValues({ chargerType: v.target.value })}
        />
      </ContentWithLabel>

      <ContentWithLabel label="Category">
        <Dropdown
          value={value.category}
          options={siteCategoryOptions}
          onChange={(v) => updateValues({ category: v.target.value })}
        />
      </ContentWithLabel>

      <ContentWithLabel label="Status">
        <Dropdown
          value={value.status}
          options={siteStatusOptions}
          onChange={(v) => updateValues({ status: v.target.value })}
        />
      </ContentWithLabel>

      <div className="flex w-full space-x-2">
        {onDelete && (
          <Button
            label="Delete"
            className="!mt-2 flex-1"
            severity="danger"
            onClick={() => {
              onDelete(value);
            }}
          />
        )}

        <Button
          type="submit"
          label="Save"
          className="!mt-2 flex-1"
          severity="success"
          onClick={() => {
            onSave(value);
          }}
        />
      </div>
    </div>
  );
}
