import { useMemo, useReducer } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataSource } from "../queries/models/data-source.dto";
import { MultiSelect } from "primereact/multiselect";
import { useAllDataSourcesQuery } from "../queries/data-sources.query";
import { Dropdown } from "primereact/dropdown";

function reducer(
  state: DataSource,
  action: { [key in keyof DataSource]?: DataSource[key] }
): DataSource {
  return {
    ...state,
    ...action,
  };
}

export function DataSourceForm({
  initialValue,
  isEdit = false,
  onSave,
  onDelete,
}: {
  initialValue: DataSource;
  onSave: (dataSource: DataSource) => any;
  onDelete?: (dataSource: DataSource) => any;
  isEdit?: boolean;
}) {
  const [value, updateValues] = useReducer(reducer, initialValue);

  const dataSourcesQuery = useAllDataSourcesQuery();

  const dataSourceOptions = useMemo(() => {
    return (
      dataSourcesQuery.data
        ?.filter((x) => x.id !== initialValue.id)
        .map((x) => {
          return {
            label: `${x.id}: ${x.name}`,
            value: x.id,
          };
        }) ?? []
    );
  }, [dataSourcesQuery.data, initialValue.id]);

  const dataSourceSpecialTypeOptions = useMemo(() => {
    return [
      { label: "None", value: 0 },
      { label: "Energy prices", value: 1 },
      { label: "Imbalance prices", value: 2 },
      { label: "Imbalance values", value: 3 },
    ];
  }, []);

  return (
    <div className="flex flex-col grey-inputs" style={{ width: 600 }}>
      <label className="mt-2">Name</label>
      <InputText
        value={value.name}
        onChange={(v) => updateValues({ name: v.target.value })}
        required
      />

      {!isEdit && (
        <>
          <label className="mt-2">Special Data Source Type</label>
          <Dropdown
            value={value.specialType}
            options={dataSourceSpecialTypeOptions}
            onChange={(v) => updateValues({ specialType: v.target.value })}
          />
        </>
      )}

      {value.specialType === 0 && (
        <>
          <label className="mt-2">Child Data Sources (Optional)</label>
          <MultiSelect
            value={value.timeSeriesChildDataSourceIds}
            options={dataSourceOptions}
            onChange={(v) =>
              updateValues({ timeSeriesChildDataSourceIds: v.target.value })
            }
            loading={dataSourcesQuery.isLoading}
          />
        </>
      )}

      <div className="flex w-full space-x-2">
        {onDelete && (
          <Button
            label="Delete"
            className="!mt-2 flex-1"
            severity="danger"
            onClick={() => {
              onDelete(value);
            }}
          />
        )}

        <Button
          type="submit"
          label="Save"
          className="!mt-2 flex-1"
          severity="success"
          onClick={() => {
            onSave(value);
          }}
        />
      </div>
    </div>
  );
}
