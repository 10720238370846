import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  DataTableFilterMeta,
  DataTableFilterMetaData,
  DataTableOperatorFilterMetaData,
  DataTableStateEvent,
} from "primereact/datatable";
import { FilterMatchMode } from "primereact/api";
import { CustomFilterMatchMode } from "../utils/data-table-custom-filters";

function removeEmptyValues(
  obj: DataTableFilterMeta
): DataTableFilterMeta | undefined {
  // remove values that don't filter anything, so url is shorter
  const entries = Object.entries(obj).filter(
    ([k, v]) =>
      v &&
      "value" in v &&
      v.value !== null &&
      v.value !== undefined &&
      (!Array.isArray(v.value) || v.value.length > 0) &&
      v.value !== ""
  );
  return entries.length ? Object.fromEntries(entries) : undefined;
}

function fixDataTableStructure(
  obj: DataTableFilterMeta,
  schema?: DataTableFiltersSchema<string>,
  applyParsers?: boolean
): DataTableFilterMeta {
  if (!schema) return obj;

  const newObj = obj ? { ...obj } : {};
  Object.entries(schema).forEach(([k, v]) => {
    newObj[k] ??= {} as any;

    // Parsers should be applied only after parsing url's filter json and if given property exists
    if (applyParsers && v.parse && newObj[k]) {
      try {
        (newObj[k] as any).value = v.parse((newObj[k] as any).value);
      } catch (e) {
        console.warn("Didn't parse value", (newObj?.[k] as any).value);
        console.warn(e);
      }
    }

    // Fill all known keys, so DataTable doesn't throw error "cannot set / read property of undefined".
    // Remove match mode when there's no value, so DataTable doesn't filter out everything when there's "custom" filter with or without any value.
    if (
      newObj[k] &&
      "value" in newObj[k] &&
      (newObj[k] as any).value !== undefined &&
      (newObj[k] as any).value !== null &&
      v.matchMode !== "custom"
    ) {
      if (!(newObj[k] as any).matchMode) {
        (newObj[k] as any).matchMode = v.matchMode;
      }
    } else if (
      ("isGlobalFieldWithDefaultValue" satisfies keyof typeof v) in v
    ) {
      // If filter field is global it has to have value and matchmode, or not exist - 1 of these - always, or DataTable throws error
      newObj[k] = {
        value: v.isGlobalFieldWithDefaultValue,
        matchMode: v.matchMode as any,
      };
    } else {
      newObj[k] = {} as any;
    }
  });
  return newObj;
}
function getOnlySchemaFilters(
  obj: DataTableFilterMeta,
  schema?: DataTableFiltersSchema<string>
): DataTableFilterMeta {
  if (!schema) return obj;

  // Remove external filters and those not in schema, so DataTable doesn't filter out everything
  const newObj = {} as DataTableFilterMeta;
  Object.entries(schema).forEach(([k, v]) => {
    if (v.external) return;
    newObj[k] = obj[k];
  });
  return newObj;
}

export type DataTableFiltersSchema<TKeys extends string> = {
  [key in TKeys]: {
    /**
     * Default match mode that DataTable should apply.
     *
     * **Remember:** `FilterMatchMode.CUSTOM` mode doesn't have any effect.
     *
     * If you want to use custom filters, first register them with
     * ```
     * import { FilterService } from "primereact/api";
     * ...
     * const myCustomFilterFunc = (value: any, filter: any): boolean => { ... };
     * FilterService.register("myCustomFilter", myCustomFilterFunc);
     * ...
     * const options = {
     *   dataTableSchema: {
     *     "filterField": {
     *       matchMode: "myCustomFilter"
     *     }
     *   }
     * }
     * ```
     */
    matchMode?:
      | FilterMatchMode
      | keyof typeof CustomFilterMatchMode
      | (string & {});
    /** Change result of json parsing, useful for handling dates */
    parse?: (prev: any) => any;
    /** True if you manage this filter instead of DataTable column, but still want this filter in same url param */
    external?: boolean;
    /**
     * If this filter is `DataTable`'s global filter field, then use this key to assign to default global field value.
     *
     * It has to be value that will match all, otherwise you will get filtered results on empty global field.
     * If you set this value to eg. `undefined`, you might get errors from `DataTable`
     */
    isGlobalFieldWithDefaultValue?: any;
  };
};

/** Helper function for declaring hook options with auto types outside hook */
export function asDataTableFilterOptions<TKeys extends string>(
  x: DataTableFiltersOptions<TKeys>
): DataTableFiltersOptions<TKeys> {
  return x;
}

export type DataTableFiltersOptions<TKeys extends string> = {
  /** Define schema of filters to manage them properly, prefferably as useMemo or outside render function */
  dataTableSchema?: DataTableFiltersSchema<TKeys>;
  /** Whether to get filters and sorting from url and save them in url */
  useUrlParams?: boolean;
  /** If you have multiple hooks on same page, the single default search param might not be enough, here you can set other name */
  customFilterUrlParamName?: string;
  /** If you have multiple hooks on same page, the single default search param might not be enough, here you can set other name */
  customSortUrlParamName?: string;
};
const defaultFilterUrlParamName = "filter";
const defaultSortUrlParamName = "sort";

export type DataTableFiltersReturnValue<TKeys extends string = string> = {
  /** All filters to pass to DataTable's `filters`, doesn't include external filters */
  readonly dataTableFilters: DataTableFilterMeta;
  /** Callback to pass to DataTable's `onFilter` */
  readonly onDataTableFilter: (event: DataTableStateEvent) => void;

  /** Sort field to pass to DataTable's `sortField`, if you want to manage sorting */
  readonly dataTableSortField: string | undefined;
  /** Sort order to pass to DataTable's `sortOrder`, if you want to manage sorting */
  readonly dataTableSortOrder: 1 | -1 | 0 | null | undefined;
  /** Callback to pass to DataTable's `onSort`, if you want to manage sorting */
  readonly onDataTableSort: (event: DataTableStateEvent) => void;

  /** Sets filter, useful for external filters */
  readonly setFilter: (
    key: TKeys,
    value: DataTableFilterMetaData | DataTableOperatorFilterMetaData
  ) => void;
  /** Gets filter, useful for external filters */
  readonly getFilter: (
    key: TKeys
  ) => DataTableFilterMetaData | DataTableOperatorFilterMetaData;
  /** Gets only filter value, if there's no filter, this will return undefined */
  readonly getFilterValue: (key: TKeys) => any;
};

/**
 * Shortcut for managed filters for DataTable.
 * Allows saving filtering state to url, with sorting.
 * DataTable has many errors when handling managed filters, this hook should ease the process a little.
 *
 * ***What works, what doesn't:***
 * - Works only with sorting by "single" field, without custom sort functions
 *
 * - Works only for filters of type `DataTableFilterMetaData`, and not for `DataTableOperatorFilterMetaData`
 * (that means no filter's expandable menu)
 *
 * - Also "custom" filters don't have any effect, unless you define your custom filter globally.
 * Look for hints in `DataTableFiltersSchema` -> `matchMode` and declarations in `dataTableCustomFilters.ts`
 *
 * You can always use external filters (`DataTableFiltersSchema` prop) and manage data by
 * filtering all available rows before passing them to DataTable
 *
 */
export function useDataTableLocalFilters<TKeys extends string>(
  options: DataTableFiltersOptions<TKeys>
): DataTableFiltersReturnValue<TKeys> {
  /** Helper for detecting if url changed without input through callbacks */
  const locationParamJson = useRef<string>();

  // initializers for filters & sorting - gets state from url if specified
  const getDefaultFilters = useCallback(() => {
    if (options.useUrlParams) {
      const params = new URLSearchParams(window.location.search);
      if (
        params.has(
          options.customFilterUrlParamName ?? defaultFilterUrlParamName
        )
      ) {
        const filterJson = params.get(
          options.customFilterUrlParamName ?? defaultFilterUrlParamName
        );
        if (filterJson) {
          locationParamJson.current = filterJson;
          return fixDataTableStructure(
            JSON.parse(filterJson) as DataTableFilterMeta,
            options.dataTableSchema,
            true
          );
        }
      }
      locationParamJson.current = undefined;
    }
    return fixDataTableStructure({}, options.dataTableSchema);
  }, [
    options.customFilterUrlParamName,
    options.dataTableSchema,
    options.useUrlParams,
  ]);

  const getDefaultSort = useCallback(() => {
    if (options.useUrlParams) {
      const params = new URLSearchParams(window.location.search);
      if (
        params.has(options.customSortUrlParamName ?? defaultSortUrlParamName)
      ) {
        const sortJson = params.get(
          options.customSortUrlParamName ?? defaultSortUrlParamName
        );
        if (sortJson) {
          return JSON.parse(sortJson);
        }
      }
    }
    return undefined;
  }, [options.customSortUrlParamName, options.useUrlParams]);

  const [filters, setFilters] =
    useState<DataTableFilterMeta>(getDefaultFilters);
  const [sortOptions, setSortOptions] = useState<
    | {
        field: string | undefined;
        order: 1 | -1 | 0 | null | undefined;
      }
    | undefined
  >(getDefaultSort);

  /** Changes filters and if specified in options changes url & replaces new state of browser history */
  const setNewFilters = useCallback(
    (newFilters: DataTableFilterMeta, merge?: boolean) => {
      setFilters((prev) => {
        newFilters = fixDataTableStructure(
          merge ? { ...prev, ...newFilters } : newFilters,
          options.dataTableSchema
        );

        if (options.useUrlParams) {
          const params = new URLSearchParams(window.location.search);
          const removedEmpty = removeEmptyValues(newFilters);
          if (removedEmpty === undefined) {
            locationParamJson.current = undefined;
            params.delete(
              options.customFilterUrlParamName ?? defaultFilterUrlParamName
            );
          } else {
            const filtersJson = JSON.stringify(removedEmpty);
            locationParamJson.current = filtersJson;
            params.set(
              options.customFilterUrlParamName ?? defaultFilterUrlParamName,
              filtersJson
            );
          }
          window.history.replaceState(
            {},
            "",
            window.location.pathname + "?" + params.toString()
          );
        }
        return newFilters;
      });
    },
    [
      options.customFilterUrlParamName,
      options.useUrlParams,
      options.dataTableSchema,
    ]
  );

  /** Changes sort options and if specified in options changes url & replaces new state of browser history */
  const setNewSort = useCallback(
    (newSortOptions: typeof sortOptions) => {
      if (options.useUrlParams) {
        const params = new URLSearchParams(window.location.search);
        const removedEmpty =
          newSortOptions?.field &&
          newSortOptions?.order !== undefined &&
          newSortOptions?.order !== null
            ? newSortOptions
            : undefined;
        if (removedEmpty === undefined) {
          params.delete(
            options.customSortUrlParamName ?? defaultSortUrlParamName
          );
        } else {
          const sortOptionsJson = JSON.stringify(removedEmpty);
          params.set(
            options.customSortUrlParamName ?? defaultSortUrlParamName,
            sortOptionsJson
          );
        }
        window.history.replaceState(
          {},
          "",
          window.location.pathname + "?" + params.toString()
        );
      }
      setSortOptions((prev) => {
        if (
          newSortOptions?.field !== prev?.field ||
          newSortOptions?.order !== prev?.order
        ) {
          return newSortOptions;
        }
        return prev;
      });
    },
    [options.customSortUrlParamName, options.useUrlParams]
  );

  // update filters / sorting only when using url search params
  // and the change wasn't triggered by any onFilter / onSort
  // also don't push here new browser history state - it already changed when it got here
  useEffect(() => {
    if (!options.useUrlParams) return;

    const params = new URLSearchParams(window.location.search);

    const filterJson =
      params.get(
        options.customFilterUrlParamName ?? defaultFilterUrlParamName
      ) ?? "{}";
    if (locationParamJson.current !== filterJson) {
      locationParamJson.current = filterJson;
      setFilters(
        fixDataTableStructure(
          JSON.parse(filterJson) as DataTableFilterMeta,
          options.dataTableSchema,
          true
        )
      );
    }
    const sortOptionsJson =
      params.get(options.customSortUrlParamName ?? defaultSortUrlParamName) ??
      "{}";
    const newSortOptions = JSON.parse(sortOptionsJson);
    setSortOptions((prev) => {
      if (
        newSortOptions?.field !== prev?.field ||
        newSortOptions?.order !== prev?.order
      ) {
        return newSortOptions;
      }
      return prev;
    });
    // should react to `window.location.search` change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    options.useUrlParams,
    options.customFilterUrlParamName,
    options.dataTableSchema,
    window.location.search,
    setNewSort,
  ]);

  const dataTableFilters: DataTableFiltersReturnValue["dataTableFilters"] =
    useMemo(
      () => getOnlySchemaFilters(filters, options.dataTableSchema),
      [filters, options.dataTableSchema]
    );

  const onFilter: DataTableFiltersReturnValue["onDataTableFilter"] =
    useCallback(
      (event: DataTableStateEvent) => setNewFilters(event.filters, true),
      [setNewFilters]
    );

  const getFilterField: DataTableFiltersReturnValue["getFilter"] = useCallback(
    (key) => structuredClone(filters?.[key]),
    [filters]
  );
  const getFilterFieldValue: DataTableFiltersReturnValue["getFilterValue"] =
    useCallback(
      (key) => structuredClone((filters?.[key] as any)?.value),
      [filters]
    );

  const setFilterField: DataTableFiltersReturnValue["setFilter"] = useCallback(
    (key, value) => setNewFilters({ [key]: value }, true),
    [setNewFilters]
  );

  const onSort: DataTableFiltersReturnValue["onDataTableSort"] = useCallback(
    (event) => {
      console.info("onSort", event);
      setNewSort({
        field: event.sortField,
        order: event.sortOrder,
      });
    },
    [setNewSort]
  );

  const result: DataTableFiltersReturnValue = useMemo(() => {
    return {
      dataTableFilters: dataTableFilters,
      onDataTableFilter: onFilter,
      getFilter: getFilterField,
      setFilter: setFilterField,
      getFilterValue: getFilterFieldValue,
      dataTableSortField: sortOptions?.field,
      dataTableSortOrder: sortOptions?.order,
      onDataTableSort: onSort,
    };
  }, [
    dataTableFilters,
    onFilter,
    getFilterField,
    getFilterFieldValue,
    setFilterField,
    sortOptions,
    onSort,
  ]);

  return result;
}
