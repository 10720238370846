import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import { authService } from "../services/auth.service";
import { Permissions } from "../utils/permissions";
import { LoggedUserInfo } from "./logged-user-info";
import { LocalStorageService } from "../services/local-storage.service";

interface AuthProviderProps {
  children: React.ReactNode;
}

interface AuthContextProps {
  userData?: LoggedUserInfo | undefined;
  hasAccess: (permission: Permissions) => boolean;
  signIn: (email: string, password: string) => Promise<boolean>;
  signOut: () => void;
}

export const AuthContext = createContext<AuthContextProps>({
  userData: undefined,
  signIn: async () => false,
  signOut: () => {},
  hasAccess: () => false,
});

export function AuthProvider({ children }: AuthProviderProps) {
  const navigate = useNavigate();

  const [userData, setUserData] = useState<LoggedUserInfo | undefined>(
    LocalStorageService.get("AUTH_USER_INFO")
  );

  useEffect(() => {
    const subscription = LocalStorageService.subscribe(
      "AUTH_USER_INFO",
      setUserData
    );

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const hasAccess = useCallback((permission: Permissions) => {
    const loggedUser = LocalStorageService.get("AUTH_USER_INFO");
    return loggedUser?.permissions.find((x) => x === permission) !== undefined;
  }, []);

  async function signIn(email: string, password: string): Promise<boolean> {
    try {
      const userInfo = await authService.login(email, password);
      if (!userInfo) {
        return false;
      }

      LocalStorageService.set("AUTH_USER_INFO", {
        id: userInfo.securityUser.id,
        roleId: userInfo.securityUser.roleId,
        permissions: userInfo.securityUser.role.permissions,
        email: userInfo.securityUser.email,
        accessToken: userInfo.token,
      });

      return true;
    } catch {
      return false;
    }
  }

  function signOut() {
    LocalStorageService.set("AUTH_USER_INFO", undefined);
    navigate("/login");
  }

  return (
    <AuthContext.Provider value={{ signIn, signOut, hasAccess, userData }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
