import { useState } from "react";
import CalendarPicker from "../components/CalendarPicker";
import { DataAnalysisComponent } from "../components/DataAnalysisComponent";
import { DataSource } from "../queries/models/data-source.dto";
import { DataSourcesSelector } from "../components/DataSourcesSelector";
import { Nullable } from "primereact/ts-helpers";
export function DataCharts() {
  const [dataSources, setDataSources] = useState<DataSource[]>([]);

  const [dates, setDates] = useState<Nullable<Date | Date[]>>([
    new Date(),
    new Date(),
  ]);

  return (
    <div>
      <div className="relative">
        <CalendarPicker value={dates} setValue={setDates}></CalendarPicker>
      </div>
      <div className="p-0 min-w-0 mr-3">
        <DataSourcesSelector
          dataSources={dataSources}
          setDataSources={setDataSources}
        />
      </div>
      {dataSources.length !== 0 && Array.isArray(dates) && dates[0] && (
        <div className="w-full">
          <DataAnalysisComponent
            dataSources={dataSources}
            from={dates[0]}
            to={dates[1] ?? dates[0]}
          />
        </div>
      )}
    </div>
  );
}
