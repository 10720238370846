import { DataSerie } from "../queries/models/data-serie.dto";
import { API_ENDPOINTS } from "../utils/endpoints";
import { BaseService } from "./base.service";

class DataAccessService extends BaseService {
  async getData(
    dataSourceIds: number[],
    from: Date,
    to: Date
  ): Promise<DataSerie[]> {
    const filtersUrl = `${this.endpointPath}/filters-config`;

    const filtersConfigId = await this.http
      .post<string>(filtersUrl, { dataSourceIds })
      .then((res) => res.data);

    const url = `${this.endpointPath}/data/${filtersConfigId}`;

    return this.http
      .get<DataSerie[]>(url, {
        params: {
          from,
          to,
        },
      })
      .then((res) => res.data);
  }
}

export const dataAccessService = new DataAccessService(
  API_ENDPOINTS.DATA_ACCESS
);
