export enum SiteStatus {
  None = 0,
  Operational = 1,
  ReadyForConnection = 2,
  HscPlaced = 3,
  PermitIssued = 4,
  PermitProcessing = 5,
  OnHold = 6,
  PermittedButOnHold = 7,
}
