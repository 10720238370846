import { useMutation, useQuery } from "@tanstack/react-query";
import { CreatePredictionDto } from "./models/create-prediction.dto";
import { predictionsService } from "../services/predictions.service";

export function useCreatePredictionMutation() {
  return useMutation({
    mutationFn: (prediction: CreatePredictionDto) => {
      return predictionsService.create(prediction);
    },
  });
}

export function useGetPredictionsQuery(refetchInterval?: number) {
  return useQuery({
    queryKey: ["predictions"],
    queryFn: () => predictionsService.getPredictions(),
    refetchInterval: refetchInterval,
  });
}

export function useGetPredictionQuery(predictionId: number) {
  return useQuery({
    queryKey: ["predictions", predictionId],
    queryFn: () => predictionsService.getPrediction(predictionId),
  });
}

export function useRerunPredictionMutation() {
  return useMutation({
    mutationFn: (predictionId: number) => {
      return predictionsService.rerun(predictionId);
    },
  });
}

export function useDeletePredictionMutation() {
  return useMutation({
    mutationFn: (predictionId: number) => {
      return predictionsService.delete(predictionId);
    },
  });
}
