import { Calendar } from "primereact/calendar";
import { useWindowSize } from "../hooks/use-window-size";
import { Nullable } from "primereact/ts-helpers";

interface CalendarPickerProps {
  label?: string;
  value: Nullable<Date> | Nullable<Date[]>;
  setValue: (dates: Nullable<Date> | Nullable<Date[]>) => void;
  single?: boolean;
}

export default function CalendarPicker({
  label = "Date range",
  value: dates,
  setValue,
  single = false,
}: CalendarPickerProps) {
  const windowSize = useWindowSize();

  return (
    <>
      <div id="calendar" className="flex flex-wrap m-0">
        <div className="my-2">
          <label className="font-semibold text-md mb-1 py-2 text-gray-500">
            {label}:
          </label>
          {single && (
            <Calendar
              value={dates as Nullable<Date>}
              onChange={(e: any) => {
                setValue(e.value);
              }}
              selectionMode="single"
              className="mx-2 min-w-max p-inputtext-centered"
              numberOfMonths={1}
              readOnlyInput
            />
          )}
          {!single && (
            <Calendar
              value={dates as Nullable<Date[]>}
              onChange={(e: any) => {
                setValue(e.value);
              }}
              selectionMode="range"
              className="mx-2 min-w-max p-inputtext-centered"
              numberOfMonths={windowSize.lg ? 2 : 1}
              readOnlyInput
            />
          )}
        </div>
      </div>
    </>
  );
}
