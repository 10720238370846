import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faChartSimple,
  faLink,
  faChartLine,
  faMapLocationDot,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { useWindowSize } from "../hooks/use-window-size";
import { SideBarNavigationDesktop } from "./SideBarNavigationDesktop";
import { SideBarNavigationMobile } from "./SideBarNavigationMobile";
import { useMemo } from "react";

export interface MenuElement {
  to: string;
  label: string;
  icon: IconProp;
}

export function SideBarNavigation() {
  const windowSize = useWindowSize();

  const menuElements: MenuElement[] = useMemo(() => {
    let elements = [
      {
        to: "sites",
        label: "Sites",
        icon: faMapLocationDot,
      },
      {
        to: "data-charts",
        label: "Data charts",
        icon: faChartSimple,
      },
      {
        to: "data-sources",
        label: "Data sources",
        icon: faLink,
      },
      {
        to: "predictions",
        label: "Predictions",
        icon: faChartLine,
      },
      {
        to: "users",
        label: "Users",
        icon: faUsers,
      },
    ] as MenuElement[];

    return elements;
  }, []);

  return (
    <>
      {windowSize.lg ? (
        <SideBarNavigationDesktop menuElements={menuElements} />
      ) : (
        <SideBarNavigationMobile menuElements={menuElements} />
      )}
    </>
  );
}
