import { Button } from "primereact/button";
import { Image } from "primereact/image";
import React, { useState } from "react";
import { useAuth } from "../auth/AuthProvider";
import { useNavigate } from "react-router-dom";
import { InputWithLabel } from "../components/InputWithLabel";
import { ThemeSwitcher } from "../components/ThemeSwitcher";

export function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoginError, setIsLoginError] = useState(false);

  const auth = useAuth();

  const navigate = useNavigate();

  async function login() {
    const success = await auth.signIn(email, password);
    setIsLoginError(!success);

    if (success) {
      navigate("/");
    }
  }

  return (
    <div className="flex flex-1 w-screen h-screen justify-center items-center">
      <div className="absolute right-0 top-5">
        <ThemeSwitcher />
      </div>
      <div className="rounded-xl border flex flex-col shadow-md min-w-[330px]">
        <div className="pt-5 pb-3 rounded-t-xl bg-[var(--primary-color)] flex flex-col items-center">
          <Image
            src="/logo.svg"
            alt="logo"
            className="w-64 h-auto mx-auto mt-8"
          />
        </div>
        <div className=" rounded-xl">
          <div className="pt-8 px-8 flex flex-col">
            <InputWithLabel
              label="Email"
              value={email}
              onChange={(v) => setEmail(v.target.value)}
            />
            <InputWithLabel
              label="Password"
              type="password"
              value={password}
              onChange={(v) => setPassword(v.target.value)}
            />
            {isLoginError && (
              <div className="text-red-500 text-sm mt-2">
                Invalid email or password
              </div>
            )}
          </div>

          <div className="flex justify-center">
            <Button
              label="Login"
              className="w-1/2 mt-8 mb-8"
              onClick={login}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
