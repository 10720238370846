import { useNavigate, useParams } from "react-router";
import { useGetPredictionQuery } from "../queries/predictions.query";
import { TimeChart, TimeChartData } from "../components/TimeChart";
import { useMemo } from "react";
import { Unit } from "../utils/units";
import { PredictionValue } from "../queries/models/prediction-value.dto";
import { PredictionStatus } from "../queries/models/prediction-status.enum";
import { Button } from "primereact/button";

function DetailItem({
  label,
  value,
}: {
  label: string;
  value: string | number;
}) {
  return (
    <div>
      <p className={`text-sm font-medium text-primary-300`}>{label}</p>
      <p className={`mt-1 text-lg text-primary-800`}>{value}</p>
    </div>
  );
}

export function PredictionDetails() {
  const { id } = useParams();

  const { data, isLoading } = useGetPredictionQuery(Number(id));

  const navigate = useNavigate();

  const timeChartData: TimeChartData[] = useMemo(() => {
    if (!data) return [];

    return [
      {
        name: "Actual Values",
        points: data.actualValues.map((point: PredictionValue) => ({
          date: new Date(point.date),
          value: point.value,
        })),
        unit: Unit.watt,
        color: "#4CAF50",
      },
      {
        name: "Predicted Values",
        points: data.predictedValues.map((point: PredictionValue) => ({
          date: new Date(point.date),
          value: point.value,
        })),
        unit: Unit.watt,
        color: "#2196F3",
      },
    ];
  }, [data]);

  const r2Error = useMemo(() => {
    if (!data || data.actualValues.length !== data.predictedValues.length)
      return null;

    const actualValues = data.actualValues.map(
      (point: PredictionValue) => point.value
    );
    const predictedValues = data.predictedValues.map(
      (point: PredictionValue) => point.value
    );
    const meanActual =
      actualValues.reduce((sum: number, val: number) => sum + val, 0) /
      actualValues.length;

    const ssRes = actualValues.reduce(
      (sum: number, actual: number, i: number) => {
        return sum + Math.pow(actual - predictedValues[i], 2);
      },
      0
    );

    const ssTot = actualValues.reduce((sum: number, actual: number) => {
      return sum + Math.pow(actual - meanActual, 2);
    }, 0);

    const r2 = 1 - ssRes / ssTot;
    return r2;
  }, [data]);

  return (
    <div>
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <p className="text-lg text-gray-600">Loading prediction details...</p>
        </div>
      ) : data ? (
        <div>
          <div className="mt-2">
            <Button
              icon="pi pi-arrow-left"
              outlined
              label="Back"
              onClick={() => navigate(-1)}
            />
          </div>
          <div className="flex flex-col md:flex-row gap-8 mt-8">
            <div className="md:w-2/3">
              <div className="h-[500px]">
                <TimeChart data={timeChartData} />
              </div>
            </div>

            <div className="md:w-1/3">
              <div className="bg-primary-100 shadow-md rounded-lg p-6">
                <h2 className="text-2xl font-bold mb-4 text-primary-800">
                  Prediction Details
                </h2>
                <div className="space-y-4">
                  <DetailItem label="ID" value={data.id} />
                  <DetailItem label="Data Source" value={data.dataSourceName} />
                  <DetailItem
                    label="Status"
                    value={PredictionStatus[data.status]}
                  />
                  <DetailItem
                    label="Days to Predict"
                    value={data.daysToPredict}
                  />
                  <DetailItem
                    label="Training Set Period"
                    value={`${new Date(
                      data.trainingSetStartDate
                    ).toLocaleDateString()} - ${new Date(
                      data.trainingSetEndDate
                    ).toLocaleDateString()}`}
                  />
                  <DetailItem
                    label="Prediction Start Date"
                    value={new Date(
                      data.predictionStartDate
                    ).toLocaleDateString()}
                  />
                  <DetailItem
                    label="Prediction accuracy (R² method)"
                    value={r2Error ? `${(r2Error * 100).toFixed(2)}%` : "N/A"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-64">
          <p className="text-lg text-red-600">
            Error loading prediction details.
          </p>
        </div>
      )}
    </div>
  );
}
