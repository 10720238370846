import { InputNumber, InputNumberProps } from "primereact/inputnumber";

interface InputNumberWithLabelProps extends InputNumberProps {
  label: string;
}

export function InputNumberWithLabel({
  label,
  ...rest
}: InputNumberWithLabelProps) {
  return (
    <div>
      <label className="font-semibold text-md mb-1 py-2 text-gray-500">
        {label}
      </label>
      <InputNumber {...rest} />
    </div>
  );
}
