import { Button } from "primereact/button";

interface FormWrapperProps {
  children: React.ReactNode;
  onSave: () => any;
  onDelete?: () => any;
}

export function FormWrapper({ children, onSave, onDelete }: FormWrapperProps) {
  return (
    <div className="flex flex-col grey-inputs" style={{ width: 600 }}>
      {children}
      <div className="flex w-full space-x-2">
        {onDelete && (
          <Button
            label="Delete"
            className="!mt-2 flex-1"
            severity="danger"
            onClick={() => {
              onDelete();
            }}
          />
        )}

        <Button
          type="submit"
          label="Save"
          className="!mt-2 flex-1"
          severity="success"
          onClick={() => {
            onSave();
          }}
        />
      </div>
    </div>
  );
}
