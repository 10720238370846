import { SideBarElementsDesktop } from "./SideBarElementsDesktop";
import { classNames } from "primereact/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { MenuElement } from "./SideBarNavigation";
import { LocalStorageService } from "../services/local-storage.service";

export function SideBarNavigationDesktop({
  menuElements,
}: {
  menuElements: MenuElement[];
}) {
  const [sideBarCollapsed, setSideBarCollapsed] = LocalStorageService.useKey(
    "PREFERENCES_SIDE_MENU_IS_COLLAPSED"
  );

  return (
    <div className="flex pt-14 fixed h-full z-5">
      <div
        className={classNames(
          "items-center m-2 shadow-xl p-1 py-5 flex justify-between flex-col bg-gradient-to-b from-[var(--primary-800)] to-[var(--primary-600)]",
          !sideBarCollapsed
            ? "rounded-3xl w-52 overflow-hidden"
            : "rounded-full w-16"
        )}
      >
        <SideBarElementsDesktop
          menuElements={menuElements}
          sideBarExpanded={!sideBarCollapsed}
        />
        <div
          onClick={() => setSideBarCollapsed(!sideBarCollapsed)}
          className={classNames(
            "cursor-pointer flex justify-content-center transition-all duration-200 ease-in-out py-2 w-full justify-center",
            !sideBarCollapsed ? "" : "rotate-180"
          )}
        >
          <FontAwesomeIcon
            icon={faCircleChevronLeft}
            className="text-white transition-all duration-200 ease-in-out"
          />
        </div>
      </div>
    </div>
  );
}
