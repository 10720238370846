import { NavLink } from "react-router-dom";
import { classNames } from "primereact/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface SideBarElementProps {
  to: string;
  label: string;
  icon: any;
  sideBarExpanded: boolean;
}

export function SideBarElementDesktop({
  to,
  label,
  icon,
  sideBarExpanded,
}: SideBarElementProps) {
  return (
    <>
      <li
        className={classNames(
          "flex align-center",
          sideBarExpanded ? "" : "text-center justify-center"
        )}
      >
        <NavLink
          data-pr-tooltip={sideBarExpanded ? undefined : label}
          to={to}
          className={({ isActive }) =>
            classNames(
              "custom-tooltip py-3 px-5 text-center text-white no-underline text-sm inline-flex align-items-center flex-nowrap",
              isActive ? "font-bold text-primary" : "",
              sideBarExpanded ? "hover:text-gray-100" : "hover:text-gray-200"
            )
          }
        >
          <i className={classNames(sideBarExpanded ? "pr-4" : "")}>
            <FontAwesomeIcon icon={icon} className="text-xl" />
          </i>
          <div
            className={classNames(
              "transition-duration-150 whitespace-nowrap",
              sideBarExpanded ? "" : "hidden"
            )}
          >
            {label}
          </div>
        </NavLink>
      </li>
    </>
  );
}
