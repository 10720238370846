import { useQuery, useMutation } from "@tanstack/react-query";
import { usersService } from "../services/users.service";
import { AddEditUser } from "./models/users/add-edit-user.dto";

export function useAllUsersQuery() {
  return useQuery({
    queryKey: ["users"],
    queryFn: () => usersService.getAll(),
  });
}

export function useCreateUserMutation() {
  return useMutation({
    mutationFn: (user: AddEditUser) => usersService.create(user),
  });
}

export function useUpdateUserMutation() {
  return useMutation({
    mutationFn: (user: AddEditUser) => usersService.update(user),
  });
}

export function useDeleteUserMutation() {
  return useMutation({
    mutationFn: (id: number) => usersService.delete(id),
  });
}

export function useGetRolesQuery() {
  return useQuery({
    queryKey: ["roles"],
    queryFn: () => usersService.getRoles(),
  });
}
